$(document).ready(function () {
  $("#newCreditCardForms").hide();

  $("#addnewCardBtn").click(function () {
    $("#newCreditCardForms").show();
    $(".addnewCardBtnWrapper").hide();
    $('input[name="payment[card_id]"]').prop("checked", false);
  });
});

$(document).ready(function () {
  $(".debt-card").on("click", function () {
    $(".debt-card").removeClass("highlighted");
    $(this).addClass("highlighted");
  });
});

$(document).ready(function () {
  // Listen for radio button change event
  $('input[name="payment[card_id]"]').change(function () {
    // Remove the 'highlight' class from all credit card sections
    $(".credit-card-wrapper").removeClass("highlighted");

    $("#newCreditCardForms").hide();
    $(".addnewCardBtnWrapper").show();

    // Add the 'highlight' class to the selected credit card section
    $(this)
      .closest(".d-flex")
      .find(".credit-card-wrapper")
      .addClass("highlighted");
  });
});

$(document).ready(function() {
  $('.debit-card').on('click', function() {
    $('.hidden-radio').prop('checked', false);

    var radioValue = $(this).prev('.hidden-radio').val();
    $('input[name="payment[payment_product_id]"][value="' + radioValue + '"]').prop('checked', true);

    $('.debit-card').removeClass('highlighted');

    $('.hidden-radio:checked').next('.debit-card').addClass('highlighted');
  });

  $('.hidden-radio:checked').next('.debit-card').addClass('highlighted');
});