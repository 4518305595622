import { get, post } from "@rails/request.js"

$("#host_check_out_button").on("click", async function (event) {
  event.preventDefault();
  let dataSet = $(this).data();
  let response = await get(`/parties/${dataSet.partyId}/host/orders/guest_total_sales?order_id=${dataSet.orderId}`);
  let data = await response.json;
  if(parseFloat(data.total_sales) === parseFloat(dataSet.totalSales)) {
    if(parseFloat(data.remaining_credits) >= 10){
      let message = window.credits_left_confirmation_message;
      message = message.replace("{credits}", `${data.remaining_credits_val}`);
      if(!confirm(message)) {
        document.getElementById("host_check_out_form").submit();
      } else {
        let amount = parseFloat(data.remaining_credits);
        let gift_card = { amount };
        let url = `/parties/${dataSet.partyId}/host/orders/${dataSet.orderId}/update_convertable_certificates`;
        let response = await post(url, { responseKind: "turbo-stream", body: { gift_card } });
        if(response.ok){
          window.showNotification('success', window.gift_card_converted_success_message);
          window.location.reload();
        } else {
          try {
            if(!response.ok){
              let body = await response.json;
              window.showNotification('error', body.body);
            }
          } catch (error) {
            console.log(response);
          }
        }
      }
    } else {
      document.getElementById("host_check_out_form").submit();
    }
  } else {
    alert(window.guest_sales_change_message_dialog);
    window.location.reload();
  }
});

$(function () {
  var progress = $(".progress-done");
  if(progress){
    progress.map((i, e) => {
      let complete = e.dataset["done"];
      e.style["width"] = `${complete}%`;
      e.style["opacity"] = "1";
    });
  }
});

