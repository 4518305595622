import { Controller } from "@hotwired/stimulus"
import { useIntersection } from "stimulus-use"
import { get, put, destroy } from "@rails/request.js"

// The HTML code for the spinner.
const spinner = `
  <td colspan="8" align="center" class="spinner_row">
    <div class="loader"></div>
  </td>`;

export default class extends Controller {

  initialize() {
  }
  
  connect() {
    window.page = 1
    window.fetchingData = false
    if ($(this.element).hasClass("scrollable")) {
      useIntersection(this)
    }
  }

  async appear () {
    if (window.fetchingData || this.hasNoMoreResultsItem) return
    await this.filter_parties(new Event('dummy_event'), false)
  }    

  async filter_parties(event, reset = true) {
    if (reset) {
      window.page = 1
    } else {
      window.page = window.page + 1
    }

    let sort_by = ''
    let sort_order = ''
    let search = ''
    const status = $(`#party_filter_status`).val();

    if ($(event.target).hasClass('sortable')) {
      sort_by =  $(event.target).data("sort-by");
      sort_order = $(event.target).data("sort-order") || 'Asc';
      $(event.target).removeClass(`${sort_order.toLowerCase()}`)
      sort_order = sort_order == 'Asc' ? 'Desc' : 'Asc'
      $(event.target).data("sort-order", sort_order);
      $(".sortable").removeClass("active");
      $(event.target).addClass(`active ${sort_order.toLowerCase()}`)      
    } else {
      sort_by = $(".sortable.active").data("sort-by");
      sort_order = $(".sortable.active").data("sort-order") || 'Asc';      
    }

    search = $(`#party_filter_search`).val();
    if (search != undefined) {
      if (search.length <= 2) {
        search = ''
      }
      if ($(event.target).is('#party_filter_search')) {
        let previous_search = $(`#party_filter_search`).data('prev-search') || '';
        if (search.length > 2) {
          $(`#party_filter_search`).data('prev-search', search);
        }
        if (search == '' && previous_search.length == 0) {
          $(`#party_filter_search`).data('prev-search', '');
          return;
        }
      }
    }

    window.fetchingData = true
    $("#parties-list").append(spinner);

    await get('/parties', {
      query: {
        page: window.page,
        status: status,
        search: search || '',        
        sort_by: sort_by,
        sort_order: sort_order,
        turbo_target: "parties-list"
      },
      responseKind: "turbo-stream"
    })

    window.fetchingData = false
    $(".spinner_row").remove();
  }
  
  get hasNoMoreResultsItem () {
    return document.getElementById("parties-list").querySelector(`[data-no-more-results]`) != null
  }

  create_booking(event) {
    const user_id = $(event.target).data("user-id");
    $("#booking_user_id").val(user_id);
    $("form#new_booking #party-date").val('');
    $("form#new_booking #party-time").val('');
  }

  async delete_booking(event) {
    if(confirm(window.booking_delete_confirm_dialog)){
      const booking_id = $(event.target).data("booking-id");
      const url = '/bookings/' + booking_id;
      await destroy(url, { responseKind: "turbo-stream" })
      window.showNotification('success',  window.booking_deleted_success_message )
    }
  }  

  async hide_party_location(event) {
    const url = $(event.target).data('url');
    await put(url, {
      body: {
        invite: {
          hide_party_address: $(event.target).is(':checked')
        }
      }
    })    
  }

}
