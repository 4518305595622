"use strict";
$(document).ready(function () {
  var table = $('#table1').DataTable({
    "dom": '<"top"i>rt<"bottom"><"clear">',
    "info": false,
    "processing": false,
    "paging": false,
    "columns": [
      null, // Title
      null, // Category
      null, // CMS Tag Name
      null, // Status
      null, // Actions
    ],
    "destroy": true
  });
});

$(document).ready(function () {
  var table = $('#table-promotions').DataTable({
    "dom": '<"top"i>rt<"bottom"><"clear">',
    "info": false,
    "processing": false,
    "paging": false,
    "columns": [
      null, // Title
      null, // Start date
      null, // End date
      null, // Country
      null, // Add Promotion
      null, // Price Type
      null, // Status
      null, // Actions
    ],
    "destroy": true
  });
});

$(document).ready(function () {
  var table = $('#table-1').DataTable({
    "dom": '<"top"i>rt<"bottom"><"clear">',
    "serverSide": false,
    "ordering": true,
    "scroller": true,
    "processing": true,
    "info": true,
    "paging": false,
    "iDisplayLength": 100,
    "columns": [
      null, // Party ID
      null, // Cons ID
      null, // Cons Name
      null, // Hostess
      null, // Bookings
      null, // Guests
      null, // orders
      null, // Party Date & Time
      null, // Location
      null, // Status
      null, // Actions
    ]
  });
});
$(document).ready(function () {
  var table = $('#table-2').DataTable({
    "dom": '<"top"i>rt<"bottom"><"clear">',
    "serverSide": false,
    "ordering": true,
    "scroller": true,
    "processing": true,
    "info": true,
    "columns": [
      null, // Booking ID
      null, // Booking Type
      null, // Guest Name
      null, // Booking Date & Time
      null, // Status
    ]
  });
});

$(document).ready(function () {
  var table = $('#table-3').DataTable({
    "dom": '<"top"i>rt<"bottom"><"clear">',
    "serverSide": false,
    "ordering": true,
    "scroller": true,
    "processing": true,
    "info": true,
    "columns": [
      null, // Guest Name
      null, // Order Total
      null, // Booking Date & Time
      null, // RSVP Status
    ]
  });
});

// Custom Search

$(document).ready(function(){
  // select notFound row
  var notFound = $("#notFound");
  // make it hidden by default
  notFound.hide();
  
  $("#searchfilter").on("keyup", function() {
    var value = $(this).val().toLowerCase();
    
    // select all items (assuming "tbody" contains your rows)
    var allItems = $("tbody tr");
    
    // get list of matched items (do not toggle them)
    var matchedItems = allItems.filter(function() {
      return $(this).text().toLowerCase().indexOf(value) > -1;
    });
    
    // hide all items first
    allItems.hide();
    
    // then show matched items
    matchedItems.show();
    
    // if no item matched, show notFound row, otherwise hide it
    if (matchedItems.length === 0) {
      notFound.show();
    } else {
      notFound.hide();
    }
  });
});
