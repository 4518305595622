import { Controller } from "@hotwired/stimulus"
import { useIntersection } from "stimulus-use"
import { get } from "@rails/request.js"

// The HTML code for the spinner.
const spinner = `
  <td colspan="8" align="center" class="spinner_row">
    <div class="loader"></div>
  </td>`;

export default class extends Controller {

  initialize() {
  }
  
  connect() {
    window.page = 1
    window.fetchingData = false
    if ($(this.element).hasClass("scrollable")) {
      useIntersection(this)
    }
  }

  async appear () {
    if (window.fetchingData || this.hasNoMoreResultsItem) return
    await this.filter_users(new Event('dummy_event'), false)
  }    

  async filter_users(event, reset = true) {
    if (reset) {
      window.page = 1
    } else {
      window.page = window.page + 1
    }

    let sort_by = ''
    let sort_order = ''
    let search = ''
    const status = $(`#user_filter_status`).val();
    const party_id = $("#users_table").data("party-id");

    if ($(event.target).hasClass('sortable')) {
      sort_by =  $(event.target).data("sort-by");
      sort_order = $(event.target).data("sort-order") || 'Asc';
      $(event.target).removeClass(`${sort_order.toLowerCase()}`)
      sort_order = sort_order == 'Asc' ? 'Desc' : 'Asc'
      $(event.target).data("sort-order", sort_order);
      $(".sortable").removeClass("active");
      $(event.target).addClass(`active ${sort_order.toLowerCase()}`)      
    } else {
      sort_by = $(".sortable.active").data("sort-by");
      sort_order = $(".sortable.active").data("sort-order") || 'Asc';      
    }

    search = $(`#user_filter_search`).val() || '';
    if (search.length <= 2) {
      search = ''
    }
    if ($(event.target).is('#user_filter_search')) {
      let previous_search = $(`#user_filter_search`).data('prev-search') || '';
      if (search.length > 2) {
        $(`#user_filter_search`).data('prev-search', search);
      }
      if (search == '' && previous_search.length == 0) {
        $(`#user_filter_search`).data('prev-search', '');
        return;
      }
    }

    window.fetchingData = true
    $("#users-list").append(spinner);

    await get(`/parties/${party_id}/guests`, {
      query: {
        page: window.page,
        status: status,
        search: search,        
        sort_by: sort_by,
        sort_order: sort_order,
        turbo_target: "guests-list"
      },
      responseKind: "turbo-stream"
    })

    window.fetchingData = false
    $(".spinner_row").remove();
  }
  
  get hasNoMoreResultsItem () {
    return document.getElementById("users-list").querySelector(`[data-no-more-results]`) != null
  }

}
